import React, { useEffect, useRef } from "react"
import styled, { css } from "styled-components"
import { font, color, device } from "../../layout/global"

import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger)
  gsap.core.globals("ScrollTrigger", ScrollTrigger)
}

const FeatureLine = ({
  id,
  info,
  titleWidth,
  textWidth,
  marginLeft,
  positionCenter,
  whiteBg,
  alignLeft,
  ko,
}) => {
  const ref = useRef(null),
    imageRef = useRef(null),
    titleRef = useRef(),
    textRef = useRef(null),
    lineRef = useRef(null),
    containerRef = useRef(null)

  // ANIMATIONS
  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      window.matchMedia(device.tabletP).matches
    ) {
      gsap
        .timeline({
          paused: true,
          scrollTrigger: {
            trigger: ref.current,
            start: "30% 70%",
          },
        })
        .fromTo(
          imageRef.current,
          { opacity: 0 },
          { opacity: 0.5, duration: 5, ease: "power4.out" }
        )
        .fromTo(
          [titleRef.current, lineRef.current, textRef.current],
          { opacity: 0, y: 10 },
          { opacity: 1, y: 0, duration: 0.5, stagger: 0.25 },
          "<+0.5"
        )
    }
  }, [])
  // ---

  return (
    <Wrapper
      titleWidth={titleWidth}
      textWidth={textWidth}
      marginLeft={marginLeft}
      positionCenter={positionCenter}
      id={id}
      ref={ref}
      alignLeft={alignLeft}
      ko={ko}
    >
      {info.image && (
        <div className="image" ref={imageRef}>
            <img 
                src={info.image} 
                alt='feature' 
                style={{
                    objectFit:'contain', 
                    objectPosition:'center center'
                }}
            />
        </div>
      )}
      <div className="container" ref={containerRef}>
        <h2
          ref={titleRef}
          dangerouslySetInnerHTML={{ __html: info.title }}
        ></h2>
        <span ref={lineRef} className="line"></span>
        <p ref={textRef} dangerouslySetInnerHTML={{ __html: info.text }}></p>
      </div>
    </Wrapper>
  )
}

export default FeatureLine

const Wrapper = styled.section`
  display: grid;
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: #000000;

  .gatsby-image-wrapper {
    width: 100%;
  }

  .container {
    display: flex;
    flex-direction: column;
    padding: 0 20vw 0 20vw;
    justify-content: center;
    transform-origin: bottom;
    z-index: 1;

    ${props =>
      props.ko &&
      css`
        align-items: center;
      `}
  }

  h2 {
    width: ${props => (props.titleWidth ? props.titleWidth : "100%")};
    margin: ${props => props.positionCenter && "0 auto"};
    text-align: ${props => (props.positionCenter ? "center" : "left")};
    position: relative;
    ${font.robotoBold};
    font-size: 6rem;
    color: ${props => (props.whiteBg ? color.greyDark : color.white)};
    line-height: 1;
    letter-spacing: 1px;
    opacity: 0;
    & span {
      color: ${props => (props.blue ? "#07c7ed" : color.red)};
    }
  }

  .line {
    margin-top: 50px;
    display: ${props => (props.ko ? "none" : "block")};
    width: 60px;
    height: 5px;
    background-color: ${color.white};
  }

  p {
    margin: ${props => (props.positionCenter ? "50px auto" : "50px 0 0 0")};
    text-align: ${props => (props.positionCenter ? "center" : "left")};
    ${props =>
      props.ko
        ? font.Noto700
        : props.whiteBg
        ? font.robotoMedium
        : font.roboto400};
    width: ${props => (props.ko ? "fit-content" : "100%")};
    text-align: ${props => (props.ko ? "center" : "left")};
    font-size: 2rem;
    color: ${props => (props.whiteBg ? "#97999b" : color.white)};
  }

  .image {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    .container {
      padding: 0 10vw 0 10vw;
    }
    .image,
    .gatsby-image-wrapper {
      height: 100%;
    }
    .image img {
      object-fit: cover !important;
    }
  }
`
