import React, { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import Draggable from 'react-draggable'
import { font, color, ease, device } from '../../layout/global'

import LeftArrow from "../../images/Products/left-arrow.svg"
import RightArrow from "../../images/Products/right-arrow.svg"

import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger)
  gsap.core.globals("ScrollTrigger", ScrollTrigger)
}

const DraggableComponent = ({ id, info, ko }) => {
  const wrapperRef = useRef(null), containerRef = useRef(null), helperRef = useRef(null)

  const [controlledPosition, setControlledPosition] = useState({ x: 0, y: 0})

  const onControlledDrag = (e, position) => {
    const {x} = position;
    setControlledPosition(prev => ({ x: x, y: prev.y }))
  }

  const timer = useRef(null)
  
  const onMouseDown = e => {
    clearInterval(timer.current)
    const dir = e.target.id === 'left' ? -10 : e.target.id === 'right' ? 10 : null 
    timer.current = setInterval(() => setControlledPosition(pre => ({x: pre.x + dir, y: pre.y})), 20)
  }
  const onMouseUp = () => {
    clearInterval(timer.current)
  }

  useEffect(() => {
    gsap.timeline({
      paused: true,
      scrollTrigger: {
        trigger: wrapperRef.current,
        start: '20% 70%'
      }
    })
    .fromTo(containerRef.current.querySelector(`.gatsby-image-wrapper`), {opacity: 0, y: 10}, {opacity: 1, y: 0, duration: 1, ease: 'power4.out'})
    .fromTo(helperRef.current, {opacity: 0}, {opacity: 1, duration: 0.5, ease: 'power4.out'}, '>-1')
  }, [])

  return (
    <Wrapper id={id} ref={wrapperRef} ko={ko}>
      <Draggable 
        axis="x" 
        position={controlledPosition}
        onDrag={onControlledDrag}
        onStart={() => {
          document.querySelector('.helper').style.opacity = 0
          document.querySelector('.helper').style.transitionDelay = '0s'
        }} 
        onStop={() => {
          document.querySelector('.helper').style.opacity = 1
          document.querySelector('.helper').style.transitionDelay = '1s'
        }}
      >
        <div className="container" ref={containerRef}>
            <img 
                src={info.image} 
                alt='draggable' 
                style={{
                    objectFit:'contain', 
                    objectPosition:'center center'
                }}
            />
        </div>
      </Draggable>
      <div className="helper" ref={helperRef}>
        <LeftArrow id="left" onMouseDown={onMouseDown} onMouseUp={onMouseUp} onMouseLeave={onMouseUp}/>
        <div className="helper-text">&nbsp;&nbsp;&nbsp;&nbsp;{info.help_text}&nbsp;&nbsp;&nbsp;&nbsp;</div>
        <RightArrow id="right" onMouseDown={onMouseDown} onMouseUp={onMouseUp} onMouseLeave={onMouseUp}/></div>
    </Wrapper>
  )
}

export default DraggableComponent

const Wrapper = styled.section`
  position: relative;
  overflow: hidden;
  padding:40px 0;

  @media ${device.desktop}{
    padding:unset;
  }

  .container {
    width: 250vh;

    @media ${device.desktop}{
      position: relative;
      left: 50vw;
      padding-bottom: 7.5vw;
    }
  }
  .container .gatsby-image-wrapper {
    pointer-events: none;
    left: 30vw;

    @media ${device.desktop}{
      left: unset;
    }
  }
  
  .helper {
    ${props => props.ko ? font.Noto700 : font.robotoMedium};
    color: ${color.white};
    font-size: 1rem;
    transition: all 0.3s ${ease.out};
    transition-delay: 0.5s;
    text-align: right;
    transform: translateY(-5vw);

    @media ${device.mobileP} {
      font-size: 1.4rem;
      text-align: center;
      margin: 0 5vw 0 5vw;
    }
    @media ${device.desktop}{
      font-size: 2rem;
      text-align: right;
      margin: 0 5vw 0 0;
    }
  }

  .helper svg {
    height: 1.2ch;
    fill: white;
  }

  .helper-text {
    display: inline-block;
    transition: all 0.3s ${ease.out};
    transition-delay: 0.5s;
  }

  #left {
    cursor: pointer;
    animation: arrowMoveLeft 3s infinite;
    animation-timing-function: ease-in-out;
  }
  #right {
    cursor: pointer;
    animation: arrowMoveRight 3s infinite;
    animation-timing-function: ease-in-out;
  }

  @keyframes arrowMoveLeft {
    0% { transform: translateX(0px) }
    50% { transform: translateX(-5px) }
    100% { transform: translateX(0px) }
  }
  @keyframes arrowMoveRight {
    0% { transform: translateX(0px) }
    50% { transform: translateX(5px) }
    100% { transform: translateX(0px) }
  }

  @media screen and (min-width: 320px) and (max-width: 767px) {
    .container { width: 480vw }
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .container { width: 380vw; margin-bottom: -20%; }
  }
  @media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .container {
      margin-top: -15%;
    }
    .helper {
      font-size: 1.5rem;
    }
  }
`