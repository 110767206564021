import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { font, device, ease } from "../../layout/global"
import CTAScroll from "../CTA-Scroll/landing"
import { useScrollPosition } from "../../hooks/useScrollPosition"

import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger)
  gsap.core.globals("ScrollTrigger", ScrollTrigger)
}

// COMPONENT
const Video = React.forwardRef(
  ({ src, className, id, imgMobile, toClick, info, ko }, ref) => {
    const [isVisible, setIsVisible] = useState(true)
    const refVideoContainer = useRef(null),
      refVideo = useRef(null),
      refCTA = useRef(null),
      refOvertitle = useRef(null),
      refTitle = useRef(null)

    useScrollPosition(({ currPos }) => {
      let position = Math.abs(currPos.y)
      position > 25 ? setIsVisible(false) : setIsVisible(true)
    }, [])

    useEffect(() => {
      gsap
        .timeline({
          paused: true,
          scrollTrigger: {
            id: "video",
            trigger: refVideoContainer.current,
            start: "top top",
            pin: true,
            pinSpacing: false,
          },
        })
        .fromTo(
          refVideoContainer.current,
          { opacity: 0 },
          { opacity: 1, duration: 0.5, delay: 0.3 }
        )
        .fromTo(
          [refOvertitle.current, refTitle.current],
          { opacity: 0, y: 5 },
          { opacity: 1, y: 0, duration: 1, stagger: 0.3 }
        )
        .fromTo(
          refCTA.current.querySelector(`.scroll-line`),
          { height: "0px" },
          { height: "30px", duration: 0.3 },
          ">-0.75"
        )
        .fromTo(
          refCTA.current.querySelector(`.scroll-text`),
          { opacity: 0 },
          { opacity: 1, duration: 0.3 },
          ">"
        )
    }, [])

    return (
      <Wrapper
        ref={ref}
        id={id}
        className={className}
        imgMobile={imgMobile}
        isVisible={isVisible}
        ko={ko}
      >
        <figure
          id="videoContainer"
          data-fullscreen="false"
          ref={refVideoContainer}
          role="presentation"
        >
          <video id="video" autoPlay playsInline muted loop ref={refVideo}>
            <source src={src} type="video/mp4"></source>
          </video>
          <div className="info-wrapper">
            <div className="text-container">
              <div
                className="overtitle"
                ref={refOvertitle}
                dangerouslySetInnerHTML={{ __html: info.overtitle }}
              ></div>
              <div
                className="title"
                ref={refTitle}
                dangerouslySetInnerHTML={{ __html: info.title }}
              ></div>
            </div>
            <CTAScroll
              className="cta"
              text="scroll"
              toClick={toClick}
              ref={refCTA}
              ko={ko}
            />
          </div>
        </figure>
      </Wrapper>
    )
  }
)

export default Video

const Wrapper = styled.div`
  position: relative;
  width: 100vw;
  max-height: 100vh;
  max-width: 177.78vh;
  background-color: #000000;

  @media ${device.mobileP} {
    height: 100vh;
  }
  @media ${device.tabletL} {
    height: 100vh;
  }
  .image-mobile {
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .gatsby-image-wrapper {
      height: 100%;
    }
    img {
      object-fit: cover !important;
    }
  }
  video {
    pointer-events: none;
    background-color: black;
    @media ${device.mobileP} {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
    }
  }
  #videoContainer {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .info-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    margin-bottom: 2.5%;
  }

  .text-container {
    width: 100%;
    opacity: ${props => (props.isVisible ? "1" : "0")};
    pointer-events: ${props => (props.isVisible ? "all" : "none")};
    transition: all 0.3s ${ease.out};
  }
  .overtitle {
    ${props => (props.ko ? font.Noto400 : font.robotoBold)};
    color: white;
    text-transform: uppercase;
    text-align: center;
    font-size: 1.4rem;
  }
  .title {
    ${props => (props.ko ? font.Noto700 : font.financierLight)};
    color: white;
    text-align: center;
    font-size: 8rem;
    line-height: 1;
  }
  .cta {
    opacity: 1;
    & .scroll-container {
      pointer-events: all;
    }
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    height: 100vh;
  }
`
