import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import { font, color, device } from "../../layout/global"

import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger)
  gsap.core.globals("ScrollTrigger", ScrollTrigger)
}

const FeatureLine = ({
  id,
  info,
  marginLeft,
  positionCenter,
  whiteBg,
  alignLeft,
  line,
  single,
  noLineBreak,
  ko,
}) => {
  const ref = useRef(null),
    questionRef = useRef(null),
    titleRef = useRef(null),
    textRef = useRef(null),
    containerRef = useRef(null)

  // ANIMATIONS
  useEffect(() => {
    gsap.fromTo(
      questionRef.current,
      { opacity: 0, y: 10 },
      {
        opacity: 1,
        y: 0,
        duration: 1,
        ease: "power4.out",
        scrollTrigger: {
          trigger: ref.current,
          start: "top 80%",
        },
      }
    )

    gsap
      .timeline({
        paused: true,
        scrollTrigger: {
          trigger: ref.current,
          start: "top 80%",
        },
      })
      .fromTo(
        questionRef.current,
        { opacity: 0 },
        { opacity: 1, duration: 1, ease: "power4.out" }
      )
      .fromTo(
        containerRef.current,
        { opacity: 0, scaleY: 0.5 },
        { opacity: 1, scaleY: 1, duration: 0.5, ease: "power2.out" },
        ">-1"
      )
      .fromTo(
        [titleRef.current, textRef.current],
        { opacity: 0, y: 5 },
        { opacity: 1, y: 0, duration: 0.5, stagger: 0.25, ease: "power4.out" },
        ">"
      )
  }, [])
  // ---

  return (
    <Wrapper
      marginLeft={marginLeft}
      positionCenter={positionCenter}
      id={id}
      ref={ref}
      alignLeft={alignLeft}
      line={line}
      single={single}
      ko={ko}
    >
      <div className="question" id="question">
        <h1 ref={questionRef}>{info.question}</h1>
      </div>
      <div className="container" ref={containerRef}>
        <h1
          ref={titleRef}
          dangerouslySetInnerHTML={{ __html: info.title }}
        ></h1>
        {line && <span className="line"></span>}
        {line || noLineBreak ? (
          <p
            ref={textRef}
            dangerouslySetInnerHTML={{ __html: info.text_mobile }}
          ></p>
        ) : (
          <p ref={textRef} dangerouslySetInnerHTML={{ __html: info.text }}></p>
        )}
      </div>
    </Wrapper>
  )
}

export default FeatureLine

const Wrapper = styled.section`
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: #000;
  padding: ${props => (props.single ? "50px 25px 100px 25px" : "100px 25px")};

  @media ${device.tabletP} {
    padding: ${props =>
      props.single ? "50px 100px 100px 100px" : "100px 100px"};
  }

  h1 {
    margin: ${props => props.positionCenter && "0 auto"};
    text-align: ${props => (props.positionCenter ? "center" : "left")};
    position: relative;
    ${props => (props.ko ? font.Noto700 : font.robotoBold)};
    font-size: ${props => (props.ko ? "1.8rem" : "4rem")};
    color: ${props => (props.whiteBg ? color.greyDark : color.white)};
    line-height: 1;
    letter-spacing: 1px;

    & span {
      color: ${color.red};
    }

    @media ${device.tabletP} {
      font-size: ${props => (props.ko ? "2.5rem" : "5rem")};
    }
  }

  p {
    margin: ${props => (props.single ? "0" : "50px 0 0 0")};
    text-align: ${props => (props.positionCenter ? "center" : "left")};
    ${props =>
      props.ko
        ? font.Noto700
        : props.whiteBg
        ? font.robotoMedium
        : font.roboto400};
    font-size: 1.4rem;
    color: ${props => (props.whiteBg ? "#97999b" : color.white)};

    @media ${device.tabletP} {
      font-size: ${props => (props.ko ? "2rem" : "2.2rem")};
    }
  }

  .question {
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 35px;
  }

  .question h1 {
    color: ${color.white};
    font-size: ${props => (props.ko ? "1.4rem" : "2rem")};
    ${props => (props.ko ? font.Noto400 : font.financierLightI)};

    @media ${device.tabletP} {
      font-size: ${props => (props.ko ? "2.5rem" : "5rem")};
    }
  }

  .line {
    margin-top: 50px;
    display: block;
    width: 60px;
    height: 5px;
    background-color: ${color.white};
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: black;
    transform-origin: bottom;
  }
`
