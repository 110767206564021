import React from "react"
import Layout from "../../layout"
import { graphql } from "gatsby"
import SEO from "../../components/SEO"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import Video from "../../components/Video/nata"
import Introduction from "../../components/Introduction/nata"
import FeatureLine from "../../components/FeatureLine"
import FeatureFull from "../../components/FeatureLine/full"
import Carousel from "../../components/Carousel/new"
import DefCarousel from "../../components/Carousel"
import Draggable from "../../components/Draggable"
import VideoDetail from "../../components/VideoDetail/nata"
import FormContact from "../../components/FormContact"

import VideoMobile from "../../components/Video/nata-mobile"
import FeatureImageMobile from "../../components/FeatureLine/image"
import FeatureMobile from "../../components/FeatureLine/nata-mobile"
import FeatureLineMobile from "../../components/FeatureLine/mobile"

// ASSETS
import PastelVideo from "../../assets/videos/Pastel-Nata/banner.mp4"
import PastelVideoMobile from "../../assets/videos/Pastel-Nata/banner-mobile.mp4"
import DetailVideo from "../../assets/videos/Pastel-Nata/single.mp4"
import DetailVideoMobile from "../../assets/videos/Pastel-Nata/single-mobile.mp4"

import { useTranslation, I18nextContext } from 'gatsby-plugin-react-i18next'

const IndexPage = ({ location }) => {
  const {t} = useTranslation()
  const breakpoints = useBreakpoint()
  const activeLanguage = React.useContext(I18nextContext).language

  return (
    <Layout 
        location={location}
        footer={t('footer', { returnObjects: true })}
        header={t('header', { returnObjects: true })}
    >
      <SEO title="Pastel de Nata" 
        domain={location?.hostname}/>

      {/* DESKTOP */}
      {!breakpoints.as && (
        <>
          <Video
            id="banner-video"
            info={t('video_intro', { returnObjects: true })}
            toClick="#introduction"
            src={PastelVideo}
            className="full-width full-height"
          />
          <Introduction 
            id="introduction" 
            info={t('intro', { returnObjects: true })} 
          />
          <DefCarousel
            showArrows={false}
            showIndicators={true}
            autoPlay={false}
            transitionTime={0}
            id="carousel-modulram"
            carouselContent={t('feature_2', { returnObjects: true })}
          />
          <FeatureFull 
            id="feature-2" 
            info={t('feature_2', { returnObjects: true })} 
          />
          <Carousel 
            id="carousel" 
            info={t('slider', { returnObjects: true })} 
          />
          <FeatureLine
            id="sliding-text-1"
            _title={t('sliding_text', { returnObjects: true })['text_1_1']}
            positionCenter
            noImage
            height="70vh"
            titleFontSize="5rem"
          />
          <FeatureLine
            id="sliding-text-2"
            _title={t('sliding_text', { returnObjects: true })['text_1_2']}
            positionCenter
            noImage
            height="70vh"
            titleFontSize="5rem"
          />
          <Draggable 
            id="sliding-image" 
            info={t('sliding_image', { returnObjects: true })} 
          />
          <VideoDetail
            id="feature-3"
            info={t('feature_3', { returnObjects: true })}
            src={DetailVideo}
            className="full-width full-height"
          />
        </>
      )}

      {/* MOBILE */}
      {breakpoints.as && (
        <>
          <VideoMobile
            id="banner-video-mobile"
            info={t('video_intro', { returnObjects: true })}
            toClick="#introduction"
            src={PastelVideoMobile}
            className="full-width full-height"
          />
          <Introduction id="introduction" info={t('intro', { returnObjects: true })} />
          <FeatureImageMobile
            id="feature-image-mobile"
            info={t('feature_1_mobile', { returnObjects: true })}
            whiteBg
          />
          <FeatureMobile
            id="feature-1-mobile"
            info={t('feature_1', { returnObjects: true })}
            noLineBreak
          />
          <FeatureImageMobile
            id="feature-image-mobile2"
            info={t('feature_2_mobile', { returnObjects: true })}
            fullHeight
          />
          <FeatureMobile 
            id="feature-2-mobile" 
            info={t('feature_2', { returnObjects: true })} 
            line 
          />
          <FeatureLineMobile
            id="first-line-mobile"
            _title={t('sliding_text', { returnObjects: true })['text_1_1']}
            positionCenter
            height="50vh"
          />
          <FeatureLineMobile
            id="second-line-mobile"
            _title={t('sliding_text', { returnObjects: true })['text_1_2']}
            positionCenter
            height="50vh"
          />
          <Draggable 
            id="sliding-image" 
            info={t('sliding_image', { returnObjects: true })} 
          />
          <VideoDetail
            id="feature-3"
            info={t('feature_3', { returnObjects: true })}
            src={DetailVideoMobile}
            className="full-width full-height"
          />
          <FeatureMobile 
            id="feature-3-mobile" 
            info={t('feature_3', { returnObjects: true })} 
            single 
          />
        </>
      )}
      {!(activeLanguage === 'br') &&
            <FormContact 
                id="form-contact" 
                info={t('form', { returnObjects: true })}
                lang={activeLanguage} 
                location={location}
            />
        }
    </Layout>
  )
}

export default IndexPage

export const QueryLanding = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["pastel", "form", "footer", "header"]}, language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
    }
  }
`
